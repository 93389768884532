<template>
  <div class="box">
    <div class="box-header border-bottom border-dark">
      <h3 class="box-title" :class="{'text-primary':isOpen}">
        <LiveEdit
          :enabled="isOpen"
          :value="title"
          :max="256"
          :min="1"
          class="pre-nowrap"
          :class="{'text-muted':!isOpen}"
          @valueChanged="onTitleChanged"
        >
          T{{ shortId }}:
        </LiveEdit>
      </h3>
      <div class="box-controls float-right mt-2">
        <Toggle
          class="box-controls float-right btn btn-toggle btn-xs btn-primary"
          :value="!isOpen"
          @click="onSingleToggleStatus(id, taxinfo.status)"
        />
        &nbsp;
        <Icon icon="bx:bxs-lock-alt" :inline="true" class="text-default" />
      </div>
    </div>
    <div class="box-body">
      <div class="row">
        <div v-if="taxRecons.length>0" class="col-12">
          <table id="example" class="table table-bordered table-hover table-striped display nowrap margin-top-10 table-responsive dataTable" cellspacing="0" width="100%" role="grid" aria-describedby="example_info" style="width: 100%;">
            <thead class="text-right">
              <tr role="row">
                <th
                  tabindex="0"
                  aria-controls="example" rowspan="1" colspan="1"
                  aria-sort="ascending" aria-label="Name: activate to sort column descending"
                  style="width: 131.667px;"
                  class="pl-1"
                >
                  &nbsp;
                </th>
                <th
                  tabindex="0"
                  aria-controls="example"
                  rowspan="1" colspan="1"
                  aria-label="Position: activate to sort column ascending"
                  style="width: 196.667px;"
                  class="px-1"
                >
                  &nbsp;
                </th>
                <th
                  tabindex="0"
                  aria-controls="example" rowspan="1" colspan="1"
                  aria-label="Office: activate to sort column ascending"
                  style="width: 98.6667px;"
                  class="px-2"
                >
                  Base
                </th>
                <th
                  tabindex="0" aria-controls="example" rowspan="1" colspan="1"
                  aria-label="Age: activate to sort column ascending"
                  style="width: 48.6667px;"
                  class="px-2"
                >
                  Recargos
                </th>
                <th
                  tabindex="0"
                  aria-controls="example" rowspan="1" colspan="1"
                  aria-label="Office: activate to sort column ascending"
                  style="width: 48.6667px;"
                  class="px-2"
                >
                  Monto
                </th>
                <th
                  tabindex="0"
                  aria-controls="example" rowspan="1" colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                  style="width: 48.6667px;"
                >
                  Saldo
                </th>
              </tr>
            </thead>
            <tbody class="text-right">
              <tr v-for="r in taxRecons" :key="r._id" role="row">
                <td class="col-1 pl-1">
                  <a
                    class="badge badge-sm badge-pill badge-dark py-1"
                    :href="`/#/recons/${r.recon_sequence}`"
                    @click.prevent="reconClicked(r)"
                  >
                    <Icon icon="ph:arrows-in-simple-light" />
                    C{{ r.recon_sequence }}
                    <p class="d-inline pl-3 text-primary">$ {{ r.amount }}</p>
                  </a>
                </td>
                <td class="px-1">
                  {{ r.taxName }}
                  <small class="d-block text-muted text-right">
                    {{ r.title }}
                  </small>
                </td>
                <td class="px-2 text-muted">
                  $ {{ r.taxBase.toFixed(2) }}
                </td>
                <td class="px-2">
                  <LiveEdit
                    :enabled="isOpen"
                    :value="r.overcharge === 0 ? r.overcharge.toFixed(2) : r.overcharge.toString()"
                    :max="256"
                    :min="1"
                    class="pre-nowrap text-info"
                    :class="{'text-muted':!isOpen}"
                    @valueChanged="onOverchargeChanged(r, ...arguments)"
                  >
                    $
                  </LiveEdit>
                </td>
                <td class="px-2">
                  $ {{ r.amount.toFixed(2) }}
                  <!-- <small class="d-block text-muted text-right">
                    Pagado: $ {{ r.taxPaid.toFixed(2) }}
                  </small> -->
                </td>
                <td>
                  $ {{ r.taxPend.toFixed(2) }}
                </td>
              </tr>
              <tr role="row">
                <td class=""></td>
                <td class="text-gray">
                  Subtotal
                </td>
                <td class="px-2 text-muted">
                  $ {{ sums.taxBase.toFixed(2) }}
                </td>
                <td class="px-2" :class="{'text-danger': sums.overcharge > 0}">
                  $ {{ sums.overcharge.toFixed(2) }}
                </td>
                <td class="px-2">
                  <strong class="text-warning">
                    <strong>
                      $ {{ sums.amount.toFixed(2) }}
                    </strong>
                  </strong>
                </td>
                <td :class="{'text-danger':sums.taxPend > 0}">
                  $ {{ sums.taxPend.toFixed(2) }}
                </td>
              </tr>
              <tr role="row">
                <td class=""></td>
                <td class="text-gray">
                  Pagado
                </td>
                <td></td>
                <td></td>
                <td class="px-2 text-danger">
                  $ {{ (sums.taxPaid * -1).toFixed(2) }}
                </td>
                <td></td>
              </tr>
              <tr role="row">
                <td class=""></td>
                <td class="text-gray">
                  Saldo
                </td>
                <td></td>
                <td></td>
                <td class="px-2" :class="{'text-danger':pend>0}">
                  $ {{ pend.toFixed(2) }}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12">
          <div class="row border-bottom border-dark py-1 pl-2">
            <div class="col-3 py-1" title="Pagos relacionados a este mes">
              Conciliaciones:
            </div>
            <div class="col-9">
              <span
                v-for="item in recons" :key="item._id"
                class="py-1 d-block xborder-bottom xborder-dark"
              >
                <a class="badge badge-sm badge-pill badge-dark py-1" href="#" @click.prevent="reconClicked(item)">
                  <Icon icon="ph:arrows-in-simple-light" />
                  {{ `C${item.recon_sequence}` }}
                  <p class="d-inline pl-3 text-primary">$ {{ item.amount }}</p>
                </a>
                <div class="box-tools float-right">
                  <button v-if="isOpen" type="button" class="btn btn-box-tool" title="Eliminar" @click.prevent="removeReconFromTaxMonth(item)">
                    <Icon icon="uil:times" />
                  </button>
                </div>
                <span class="d-block text-truncate pl-1">
                  {{ item.title }}
                </span>
              </span>
              <LiveSearch
                v-if="isOpen"
                ref="livesearch"
                class="py-1 d-block"
                empty="Buscar conciliación..."
                value=""
                :enabled="isOpen"
                :livesearch-values="searchresults"
                @searchValueChanged="onReconSearch"
                @livesearchItemClick="addReconToTaxMonth"
              />
            </div>
          </div>
          <div class="row py-3">
            <div class="col">
              <CommentsPane
                class="col-md-12 col-lg-6 col-sm-12"
                objecttype="cfdi/annotation"
                :parentid="cid"
                :refresh="refresh"
                :enablecomment="isOpen"
                @error="commentError"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { Icon } from "@iconify/vue2";
import { format, parse } from "date-fns";
import esmx from "date-fns/locale/es";
import debounce from "lodash/debounce"; // no se puede lazy load
// import { formatMoney } from "accounting-js";
import Toggle from "@/components/Toggle.vue";
import LiveEdit from "@/components/LiveEdit.vue";
import LiveSearch from "@/components/LiveSearch.vue";

import ServiceApi from "./CfdiMonth.service";
import CfdiServiceApi from "./Cfdi.service";

const CommentsPane = () => import("@/components/CommentsPane.vue");

export default {
  components: {
    Icon,
    Toggle,
    LiveEdit,
    LiveSearch,
    CommentsPane
  },
  props: {
    monthData: {
      type: Object,
      default() {
        return {
          _id: null,
          status: "open",
          title: "<desconocido>",
          recons: []
        };
      }
    },
  },
  data() {
    return {
      taxinfo: {},
      searchresults: [],
      searching: false,
      refresh: 0,
      taxTagNames: {
        "isr-sal": "ISR Salarios",
        "isr-ret": "ISR Rentenciones",
        "isr-arr": "ISR Arrendamientos",
        "isr-asim": "ISR Asimilados",
        "isr-hono": "ISR Honorarios",
        "isr-pmoral": "ISR Personas Morales",
        "iva-ret": "IVA Retenciones",
        iva: "IVA Causado",
        isertp: "ISERTP",
        imss: "IMSS/INFONAVIT"
      }
    };
  },
  computed: {
    cid() {
      const { _id = "" } = this.taxinfo ?? {};
      return _id;
    },
    id() {
      const { yearmonth = null } = this.taxinfo ?? {};
      // console.log("estoy mandando el id", yearmonth, this.data);
      return yearmonth;
    },
    isOpen() {
      const { status = "open" } = this.taxinfo ?? {};
      return status === "open";
    },
    recons() {
      const { recons: r = [] } = this.taxinfo ?? {};
      return r;
    },
    title() {
      const { title = "", yearmonth = 199912 } = this.taxinfo ?? {};
      if (!title) {
        const d = parse(yearmonth, "yyyyMM", new Date(), { locale: esmx } );
        const humanDate = format(d, "MMMM yyyy",  { locale: esmx } );
        return `Impuestos ${humanDate}`;
      }
      return title;
    },
    amount() {
      let { amount = 0 } = this.taxinfo ?? {};
      amount = parseFloat(amount);
      amount = Number.isNaN(amount) ? 0 : amount;
      return amount.toFixed(2);
    },
    shortId() {
      const { yearmonth = null } = this.taxinfo ?? {};
      if (Number.isNaN(parseInt(yearmonth, 10))) {
        return "0000";
      }
      const d = parse(yearmonth, "yyyyMM", new Date(), { locale: esmx } );
      const shortDate = format(d, "yyMM", { locale: esmx } );
      return shortDate;
    },
    pend() {
      let { amount = 0, taxPaid = 0 } = this.sums ?? {};

      amount = parseFloat(amount);
      amount = Number.isNaN(amount) ? 0 : amount;

      taxPaid = parseFloat(taxPaid);
      taxPaid = Number.isNaN(taxPaid) ? 0 : taxPaid;

      const saldopend = amount - taxPaid;
      return saldopend; // formatMoney(saldopend, { symbol: "", format: "%v" });
    },
    taxRecons() {
      const { recons: rows = [] } = this.taxinfo ?? {};
      const keys = Object.keys(this.taxTagNames);
      // 1. Filtramos por tipo
      // 2. Ordenamos el array (keys nos dara el orden con un indexOf)
      // 3. Sumamos pagados de cada recon
      const taxes = rows.filter(it => it.type === "porpagar" && it.tags.indexOf("impuestos") > -1)
        .sort((a, b) => {
          const { tags: atags = [] } = a ?? {};
          const [, ataxTag = ""] = atags;
          const anum = keys.indexOf(ataxTag);

          const { tags: btags = [] } = b ?? {};
          const [, btaxTag = ""] = btags;
          const bnum = keys.indexOf(btaxTag);
          // console.log(anum, ataxTag, "-", bnum, btaxTag);
          if (anum === bnum) {
            return 0;
          }
          return anum - bnum;
        })
        .map(it => {
          const {
            amount = 0, overcharge = 0, tags = [], rlogs = []
          } = it;
          const [, tag = ""] = tags;
          const taxName = this.taxTagNames[tag] ?? "Desconocido";

          const taxPaid = rlogs.reduce((accum, curr) => { // de rlogs extraemos lo pagos y los sumamos en paid
            const { meta = {} } = curr;
            const { status = "_invalid_", amount: amt = 0 } = meta;
            if (status === "shown") {
              // eslint-disable-next-line no-param-reassign
              accum += amt;
              return accum;
            }
            return accum;
          }, 0);

          return {
            ...it,
            overcharge,
            taxBase: amount - overcharge, // taxPaid - overcharge,
            taxName,
            taxPend: amount - taxPaid,
            taxPaid,
          };
        });
      // console.log(taxes);
      return taxes;
    },
    sums() {
      const { taxRecons = [] } = this;
      const sum = taxRecons.reduce((prev, curr) => {
        let {
          amount = 0, overcharge = 0, taxBase = 0, taxPaid = 0, taxPend = 0
        } = curr;

        amount = parseFloat(amount);
        amount = Number.isNaN(amount) ? 0 : amount;
        overcharge = parseFloat(overcharge);
        overcharge = Number.isNaN(overcharge) ? 0 : overcharge;
        taxBase = parseFloat(taxBase);
        taxBase = Number.isNaN(taxBase) ? 0 : taxBase;
        taxPaid = parseFloat(taxPaid);
        taxPaid = Number.isNaN(taxPaid) ? 0 : taxPaid;
        taxPend = parseFloat(taxPend);
        taxPend = Number.isNaN(taxPend) ? 0 : taxPend;

        return {
          amount: amount + prev.amount,
          overcharge: overcharge + prev.overcharge,
          taxBase: taxBase + prev.taxBase,
          taxPaid: taxPaid + prev.taxPaid,
          taxPend: taxPend + prev.taxPend
        };
      }, {
        amount: 0,
        overcharge: 0,
        taxBase: 0,
        taxPaid: 0,
        taxPend: 0
      });
      return sum;
    }
  },

  watch: {
    monthData: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old ) {
        console.log("Reload cfdis", val);
        this.fillInitial();
      }
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    onTitleChanged: debounce(function (value) {
      console.log("title val", value);
      const { yearmonth = null } = this.taxinfo ?? {};
      if (!yearmonth) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo cambiar título",
          text: "El identificador esta vacío."
        });
      }
      ServiceApi.updateByYearMonth(yearmonth, { title: value })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.fillInitial();
          // this.$emit("requestRefresh", this.taxinfo);
        })
        .catch(err => {
          const { response = {}, message = "Es posible que no tenga conexión a internet" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: "No se pudo cambiar título",
            text: `${errstr}, CODE:${statusres}`
          });
        });
    }, 500),
    // eslint-disable-next-line func-names
    onOverchargeChanged: debounce(function (recon, value) {
      const { yearmonth = null } = this.taxinfo ?? {};
      const { _id: reconid = "", recon_sequence = 0 } = recon ?? {};
      console.log("recondid, overcharge", recon, value);
      if (!yearmonth || !reconid) {
        this.messageAlert({
          icon: "error",
          title: `No se pudo cambiar recargo de C${recon_sequence}`,
          text: "El identificador esta vacío."
        });
      }
      ServiceApi.updateReconOvercharge(yearmonth, { overcharge: value, reconid })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.fillInitial();
          // this.$emit("requestRefresh", this.taxinfo);
        })
        .catch(err => {
          const { response = {}, message = "Es posible que no tenga conexión a internet" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: `No se pudo cambiar recargo de C${recon_sequence}`,
            text: `${errstr}, CODE:${statusres}`
          });
        });
    }, 500),
    // eslint-disable-next-line func-names
    onAmountChanged: debounce(function (value) {
      console.log("title val", value);
      const { yearmonth = null } = this.taxinfo ?? {};
      if (!yearmonth) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo cambiar la cantidad",
          text: "El identificador esta vacío."
        });
      }
      ServiceApi.updateByYearMonth(yearmonth, { amount: value })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.fillInitial();
          // this.$emit("requestRefresh", this.taxinfo);
        })
        .catch(err => {
          const { response = {}, message = "Es posible que no tenga conexión a internet" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: "No se pudo cambiar la cantidad",
            text: `${errstr}, CODE:${statusres}`
          });
        });
    }, 500),
    onSingleToggleStatus(yearmonth, status) {
      console.log("TOGGLE STATUS", status);
      const { yearmonth: _yearmonth = null } = this.taxinfo;
      if (!_yearmonth || _yearmonth !== yearmonth) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo activar/desactivar",
          text: "El identificador no coincide con el elemento seleccionado"
        });
        return;
      }
      const statuschanged = status === "open" ? "locked" : "open";
      this.taxinfo.status = statuschanged;
      ServiceApi.updateByYearMonth(yearmonth, { status: statuschanged })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          // console.log("res info", res);
          // this.taxinfo = res;
          this.fillInitial();
          // this.$emit("requestRefresh", this.taxinfo);
        })
        .catch(err => {
          const { response = {}, message = "Es posible que no tenga conexión a internet" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: "No se pudo activar/desactivar",
            text: `${errstr}, CODE:${statusres}`
          });
        });
    },
    onReconSearch(val) {
      console.log("onReconSearch", val);
      if (!this.searching) {
        this.searching = true;
        CfdiServiceApi.searchRecons({ search: val })
          .then(res => {
            this.searching = false;
            const { rows = [] } = res;
            const results = rows.map(it => ({
              id: it._id,
              label: `C${it.recon_sequence} ${it.title}: ${it.amount}`,
              item: it
            }));
            // console.log("SEARCH", res);
            this.searchresults = results;
          })
          .catch(err => {
            this.searching = false;
            console.log("Error in search", err);
          });
      }
    },
    addReconToTaxMonth(id, recon) {
      console.log("Request recon swap to", id, recon);
      const { yearmonth = null } = this.taxinfo ?? {};
      if (!yearmonth) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo agregar conciliación",
          text: "El identificador yearmonth esta vacio"
        });
        return;
      }
      this.searchresults = [];
      this.searching = false;
      ServiceApi.pushReconByYearMonth(yearmonth, { reconid: id })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$nextTick(() => {
            this.$refs.livesearch.v = "";
          });
          this.fillInitial();
        })
        .catch(e => {
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("RECON ADD FAILED ", errStr);
          this.$nextTick(() => {
            this.$refs.livesearch.v = "";
          });
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo agregar conciliación",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    removeReconFromTaxMonth(item) {
      const { yearmonth = null } = this.taxinfo ?? {};
      if (!yearmonth) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo agregar conciliación",
          text: "El identificador yearmonth esta vacio"
        });
        return;
      }

      const { _id: reconid = "", recon_sequence: reconSeq = 0, title = "" } = item ?? {};
      const name = `C${reconSeq} ${title}`;
      if (!reconid) {
        this.$swal({
          position: "bottom-end",
          toast: true,
          icon: "error",
          title: "No se pudo eliminar conciliación",
          text: "El Id de conciliación está vacio.",
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        });
        return;
      }
      this.$swal({
        icon: "warning",
        title: "Eliminar conciliación",
        html: `Conciliación: <strong>${name}</strong>.`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí, Eliminar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#ff7674",
        cancelButtonColor: ""
      }).then((result) => {
        // console.log(result);
        if (result.isConfirmed) {
          ServiceApi.pullReconByYearMonth(yearmonth, { reconid })
            // eslint-disable-next-line no-unused-vars
            .then(res => {
              // console.log("dbUpdated", res);
              this.fillInitial();
            })
            .catch(e => {
              const { message = "Internal server error", response = {} } = e;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errStr = message } = dataResponse;
              console.log("RECON DELETE FAILED ", errStr);
              this.$swal({
                position: "bottom-end",
                toast: true,
                icon: "error",
                title: "No se pudo eliminar conciliación",
                text: `${errStr}, CODE: ${status}`,
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true
              });
            });
        }
      });
    },
    reconClicked(item) {
      const { recon_sequence: reconSeq = 0 } = item ?? {};
      this.$router.push({
        name: "ReconsSingle",
        params: { id: reconSeq }
      });
    },
    fillInitial() {
      const { _id: yyyyMM = null } = this.monthData ?? {};
      console.log("Refilling from server", yyyyMM);
      if (!yyyyMM) {
        console.log("empty tax ID");
        return;
      }
      ServiceApi.getByYearMonth(yyyyMM)
        .then(res => {
          if (!res) {
            console.log("Got empty");
            return;
          }
          // console.log("Got", res);
          this.taxinfo = res;
        });
    },
    commentError(err) {
      const { message: errstr = "Servidor no disponible", status = 500 } = err;
      this.messageAlert({
        icon: "error",
        title: "No se pudo guardar <b>comentario</b>",
        text: `${errstr}, CODE: ${status}`
      });
    },
    messageAlert(info) {
      const {
        icon = "", title = "", text = "", timer = 3000
      } = info ?? {};
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon,
        title,
        timer,
        html: text,
        confirmButtonText: "Ok",
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true
      });
    }
  }
};
</script>
