import axios from "axios";
import store from "@/store";

const Api = {
  baseurl() {
    return `${store.state.API_ROOT}/api/cfdi/annotation`;
  },
  // TODO: Es solo un ejemplo: revisar las funciones, seguramente las url esten mal
  getByYearMonth(yearmonth) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/cfdi/annotation/${yearmonth}`;
      axios.get(url, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding by uuid", err);
          reject(err);
        });
    });
  },
  updateByYearMonth(yearmonth, query) {
    // console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/cfdi/annotation/${yearmonth}`;
      // console.log("updating", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR updating ", err);
          reject(err);
        });
    });
  },
  pushReconByYearMonth(yearmonth, data) {
    const query = data;
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/cfdi/annotation/${yearmonth}/recons`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating CFDI array", err);
          reject(err);
        });
    });
  },
  pullReconByYearMonth(yearmonth, data) {
    const { reconid = "" } = data;
    return new Promise((resolve, reject) => {
      if (!reconid || !yearmonth) {
        reject(new Error("Missing reconid"));
        return;
      }
      const url = `${store.state.API_ROOT}/api/cfdi/annotation/${yearmonth}/recons/${reconid}`;
      axios.delete(url, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating CFDI array", err);
          reject(err);
        });
    });
  },
  updateReconOvercharge(yearmonth, data) {
    const { reconid = "", overcharge = 0 } = data;
    const query = { overcharge };
    return new Promise((resolve, reject) => {
      if (!reconid || !yearmonth) {
        reject(new Error("Missing reconid"));
        return;
      }
      if (Number.isNaN(parseFloat(overcharge))) {
        reject(new Error("Overcharge must be a number"));
        return;
      }
      const url = `${store.state.API_ROOT}/api/cfdi/annotation/${yearmonth}/recons/${reconid}/overcharge`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Changing recon overcharge", err);
          reject(err);
        });
    });
  },
};

export default Api;
